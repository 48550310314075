<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Budget Head</th>
          <th>Account Head</th>
          <th>July</th>
          <th>Aug</th>
          <th>Sep</th>
          <th>Oct</th>
          <th>Nov</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        <td>
          <input class="form-control text-end" placeholder="Budget Head"/>
        </td>
        <td>
          <input class="form-control text-end" placeholder="Account Head"/>
        </td>
        <td>5000.00</td>
        <td>6000.00</td>
        <td>3000.00</td>
        <td>4000.00</td>
        <td>5000.00</td>
        <td>23000.00</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
