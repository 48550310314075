<template>
  <div class="computed-min-height card">
    <div class="bg-blue-light p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="Budget Overview Report"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">

        <BudgetInfo/>

      </div>
    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import BudgetInfo from '@/components/molecule/procurement/budget/report/BudgetInfo.vue'
import ListTable from '@/components/molecule/procurement/budget/report/budget-overview/BudgetOverviewTable'
import Pagination from '@/components/atom/Pagination'

function onClickAddNewButton() {
  //todo
}

function getBudgetOverviews() {
  //todo
}

function onPageChange() {
  //todo
}

</script>
